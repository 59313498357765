import React from 'react';
import Layout from './../components/Layout'
import AboutMe from '../components/aboutMe';
import Work from './../components/Work';
import LatestBlogs from './../components/LatestBlogs'
import Projects from './../components/Projects';
import Contact from './../components/Contact';
import {Helmet} from 'react-helmet';
import './../styles/global.scss'




function index(props) {
  
  return ( 
  
      <div>
          <Layout>
          <Helmet>
              
              <title>Momar</title>
              <link rel="canonical" href="https://www.momar.dev" />
              <meta name="description" content="Momar Faly TOURE, a graduate student in computer science. Here is my portfolio, my personal website, I present my cursus, projects that I have worked on and by blog ."/>

            </Helmet>
            <AboutMe/>
            <LatestBlogs/>
            <Work/>
            <Projects/>
            <Contact/>
          </Layout>
      </div>
     
  );
}

export default index;