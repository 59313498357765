import React,{ useRef, useState }from 'react';
import {makeStyles, Snackbar, Button, CircularProgress} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import firebase from "gatsby-plugin-firebase"



const useStyles = makeStyles({
    
    contact:{
        marginTop:110,
        paddingTop:60,
        display:"grid",
        gridTemplateColumns:"repeat(auto-fit,minmax(332px,1fr))",
        justifyContent:"space-between",
        alignItems:"flex-start",
        gap:40,

        '@media (max-width:752px)': {
            gap:0
        }
        
    },
    textAndImage:{

        '@media (max-width:711px)': {
            textAlign:"center",
            "& img":{
                display:"none",
            },
            "& .gatsby-image-wrapper, .gatsby-image-wrapper-constrained":{
                display:"none",
            }
        },


        "& h1":{
            fontSize:40,
            fontWeight:500,
            marginBottom:30,

            '@media (max-width:560px)': {
                fontSize:35,
             }
        },
        "& strong":{
            color:"#68F4A9",
            
        },
        "& p":{
            borderLeft: "4px solid #68F4A9",
            paddingLeft: 8,
            fontSize: 19,
            lineHeight: "150%",
            color:"#EAEAEA",

            '@media (max-width:712px)': {
                border:"none",
            }
        },
        '@media (max-width:1066px)': {
            "& h1":{
                fontSize:40,
            },
            "& p":{
                fontSize: 16,
            },

        },
    
    },
    form:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-evenly",
        alignItems:"center",
        gap:30,
        "& label":{
            color:"#FFFFFF",
            fontSize:17,
        },
        '@media (max-width:711px)': {
            marginTop:20,
        }
        
    },
    formA:{
        
    },
    input :{
        background:"rgba(255, 255, 255, 0.05)",
        border: "2px solid #032E3C",
        borderRadius:4,
        width:"100%",
        height: 50,
        color:"#EAEAEA",
        paddingLeft:10,

        '@media (max-width:1066px)': {
            height:42,
        },

        "&:focus":{
            outline:"none",
            boxShadow:" 0 0 4px #68F4A9",
        }

    },
    form1stLine:{
        display:"flex",
        justifyContent:"space-between",
        gap:20,
        width:"100%",
      
        
    },
    email:{
        
        flex:"2 1 300px",
        minWidth:"180px",

    },
    name:{
        flex:"1 2 200px",
        minWidth:"130px",
        
    },

    textarea:{
      display:"block", 
      height:180,
      width:"100%",
      padding:10,
      resize:"none", 
      
    },
    submit:{  
        marginLeft:"auto",
        background:"#68F4A9",
        border:"none",
        textTransform:"none",
        borderRadius:4,
        width:100,
        height:46,
        transition:"all .1s ease-in-out",
        "& span":{
            color:"#032E3B",
            fontSize:18,
            fontWeight:"bold",
        },
        
        "&:hover":{
            transform: "scale(1.02)",
            background:"#68F4A9",
            boxShadow:"none",

        },
        "&:focus":{
            background:"#68F4A9",
        },
        '@media (max-width:1066px)': {
            height:42,
        },

    },
    button:{
        background:"#4caf50",
        border:"none",
        boxShadow:"none",
        padding:"0 20",
        height:48,
        "& span":{
            fontWeight:"500",
            fontSize:"16px",
            textTransform:"none",
        },
        "&:focus":{
            background:"#4caf50",
        }
    },
    errorButton:{
        background:"#F44336"
    },
    
   
})

function Contact(props) {

    const classes = useStyles();

    const data = useStaticQuery(graphql`
        {
            contentfulGeneralInfos {
                contact {
                    childMdx {
                        body
                    }
                }
            }
            file(relativePath: {eq: "contact-me.png"}) {
                childImageSharp {
                  gatsbyImageData(breakpoints: [ 750, 900, 1100], backgroundColor: "transparent",placeholder:NONE,quality:100)
                }
              }
        }
    `)
    
    const contactText = data.contentfulGeneralInfos.contact.childMdx.body;
    
    //for the form
    const emailRef = useRef()
    const nameRef = useRef()
    const messageRef = useRef()
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
 

    async function handleSubmit(e){
        try{
            e.preventDefault();
            setLoading(true)
            const formData = {name:nameRef.current.value,email:emailRef.current.value,message:messageRef.current.value};
            const sendMessage = firebase.functions().httpsCallable("sendMessage");
            const request = await sendMessage(formData);
            console.log(request);
            setMessage("Your message has been sent successfully !")
            setLoading(false);
            return request;
        }catch(error){
            console.log(error);
            setError("There has been an error, please try again !")
            setLoading(false)
            return error;
        }
        
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setMessage("");
            setError("");
          return;
        }
        setMessage("");
        setError("");
    }

    return (
        <div className={classes.contact} id="contact">
            
            <div className={classes.textAndImage}>
                <MDXRenderer className={classes.text}>{contactText}</MDXRenderer>
                <GatsbyImage className={classes.image}  image={data.file.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={classes.formA}>
                
                <Snackbar  open={message} autoHideDuration={4000} onClose={handleClose}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        disable={true}
                        startIcon={<CheckCircleIcon/>}
                    >{message}</Button>
                </Snackbar> 
                
                <Snackbar  open={error} autoHideDuration={4000} onClose={handleClose}>
                    <Button
                        variant="contained"
                        className={classes.button+" "+classes.errorButton}
                        disable={true}
                        startIcon={<ErrorIcon/>}
                    >{error}</Button>
                </Snackbar> 
                
                <form className={classes.form} onSubmit={handleSubmit}>
                    <div className={classes.form1stLine}>
                        <div className={classes.name}>
                            <label style={{display:"block", marginBottom:10}} for="name">Name</label>
                            <input className={classes.input} type = "text" ref = {nameRef} name = "name" required/>
                        </div>
                        <div className={classes.email}>
                        <label style={{display:"block", marginBottom:10}} for="email">Email</label>
                            <input className={classes.input} type = "email" ref={emailRef} name = "email" required/>
                        </div>
                    </div>
                    <div style={{width:"100%"}}>
                        <label style={{display:"block", marginBottom:10}} for="message">Message</label>
                        <textarea ref={messageRef} className={classes.textarea+' '+classes.input} id="message" name="message" lang="en" required></textarea>
                    </div>
                    <Button
                        variant="contained"
                        type="submit"
                        className={classes.submit}
                    >{loading ? <CircularProgress size="25px" color="#032E3B" thickness={6}/> :"Send"}</Button>
                    
                </form> 

            </div>
            
        </div>
    );
}

export default Contact;