import React from 'react';
import {makeStyles, Card,Chip} from '@material-ui/core'
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import firebase from "gatsby-plugin-firebase"

const useStyles = makeStyles({

    projectsSection:{
        marginTop:20,
        paddingTop:100,

    },
    top:{
        marginBottom:30,
        "& h2":{
            fontWeight: 500,
            fontSize: 40,
            color:"#EAEAEA",
            '@media (max-width:711px)': {
                textAlign:"center",
                fontSize: 35,
            }
        },
        "& span":{
            color:"#68F4A9"
        }
    },
    projects:{
        display:"grid",
        gap:"1rem",
        justifyContent:"center",
        gridAutoRows:"460px",
        gridTemplateColumns: "repeat(auto-fit,minmax(360px,1fr))",

        '@media (max-width:1160px)': {
            gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))",
        },
        '@media (max-width:890px)': {
            gridTemplateColumns: "repeat(auto-fit,minmax(270px,1fr))",
        },

        "& a":{
            width:"100%",
        }

    },
    project:{
        position:"relative",
        backgroundColor:"#032E3C",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"flex-start",
        height:"100%",
        borderRadius:10,
        width:"100%",
        boxShadow: "rgba(2, 8, 20, 0.05) 0px 0.175em 0.5em",

        "&:hover" :{
            boxShadow: "rgba(2, 8, 20, 0.3) 0px 0.35em 1.175em",
            transform: "translateY(-0.2px) scale(1.01)",
         },
        

    },
    image:{
        cornerRadius:"6px",
        marginTop:"5%",
        width:"90%",
        height:"50%",
        objectFit:"cover",

    },
   
    infosContainer:{
        width:"90%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"flex-start",
        height:"40%",
        marginTop:30,
        marginBottom:0,

        "& h4":{
            fontSize: 20,
            fontWeight:500,
            marginBottom:20,
            color:"#FFFFFF",
        },
        "& p":{
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "142%",
            color:"#EAEAEA",
            '@media (max-width:700px)': {
                fontSize: 15,
            }
        }
    },
    
    tags:{
        position:"absolute",
        bottom:8,
    },
    tag:{
        marginRight:"5px",
        marginBottom:"10px",
        borderColor:"#68F4A9",
        background:"rgb(104, 244, 169,0.2)",

        "& span":{
            color:"#68F4A9",
            fontSize:12,
        }
    }
    



})

const registerEvent = (event) =>{
    firebase.analytics().logEvent(`project-${event}-viewed`);
}


function Projects(props) {

    const classes = useStyles();
    const data = useStaticQuery(graphql`
    {
        allContentfulProjects(limit:6, sort: {fields: order}) {
          nodes {
            description
            id
            link
            tags
            title
            illustration {
              gatsbyImageData(placeholder:BLURRED)
            }
          }
        }
      }
    `)
    const projects = data.allContentfulProjects.nodes
    
    return (
        <div className={classes.projectsSection} id="projects">
            <div className={classes.top}>
                <h2>Noteworthy projects</h2>
            </div>
            <div className={classes.projects}>
            {
                projects.map((project) => {
                    
                    return <a href={project.link} onClick={()=> registerEvent(project.title)} target="_blank" rel="noreferrer" key={project.id}>
                            <Card className={classes.project}>
                            <GatsbyImage className={classes.image} image={project.illustration.gatsbyImageData} alt="image-blog"/>
                            <div className={classes.infosContainer}>
                                <h4>{project.title}</h4>
                                <p>{project.description}</p>
                                <div className={classes.tags}>
                                    {
                                    project.tags.map((tag, key) => {
                                        return <Chip className={classes.tag} variant="outlined" key={key} label={tag}/>
                                    })
                                }
                                </div>
                            </div>
                           
                            </Card>
                        </a>
                })
                }
            </div>

        </div>
    );
}

export default Projects;