import React from 'react';
import {makeStyles} from '@material-ui/core';
import { StaticImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import { MDXRenderer } from 'gatsby-plugin-mdx';

const useStyles = makeStyles({
    about:{
        marginTop: 230,

        '@media (max-width:875px)': {
            marginTop:130,
         },
    },
    container:{
        display:"flex",
        flexDirection:"row",
        justifyContent: "space-between",
        alignItems:"center",
        padding:0,

        '@media (max-width:875px)': {
           flexDirection:"column-reverse",
           justifyContent:"space-evenly",
           gap:50,
        },

        
    },
    infos:{
        '@media (max-width:875px)': {
            textAlign:"center",
         },

        "& h1":{
            fontWeight:500,
            lineHeight:"68%",
            letterSpacing:"0.02em",
            fontSize:"50px",
            margin:0,

            '@media (max-width:940px)': {
                fontSize:"47px",
                letterSpacing:"0.01em",
            },
            '@media (max-width:600px)': {
                fontSize:"42px",
                letterSpacing:"0.01em",

            },
            '@media (max-width:500px)': {
                fontSize:"40px",
                letterSpacing:"0em",
            },
            '@media (max-width:350px)': {
                fontSize:"35px",
                letterSpacing:"0em",
            },
            
           

        },
        "& p":{
            maxWidth: 500,
            fontSize: 17.5,
            lineHeight: "160%",
            color: "#EAEAEA",
            margin:0,
            padding:0
        },
        "& p:nth-of-type(2)": {
            marginBottom:15,
         },
        "& strong":{
            color:"#68F4A9",
            fontWeight:"normal",
        }
    },

    hiThere:{
        display:"flex",
        alignItems:"baseline",
        gap:20,
        justifyContent:"flex-start",
        '@media (max-width:875px)':{
            justifyContent:"center"
        }
    },
    hello:{

        '@media (max-width:940px)': {
            width:"37px",
        },
        '@media (max-width:500px)': {
            width:"34px",
        },
        '@media (max-width:350px)': {
            width:"28px",
        },
    },
    nameAndSocials:{
        marginTop:24,
        marginBottom:44,
        display:"flex",
        alignItems:"flex-end",
        '@media (max-width:875px)': {
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            gap:20,
         },
    },

    socials:{
        display:"inline-flex",
        justifyContent:"space-between",
        alignItems:"center",
        width:115,
        marginLeft: 20,

        '@media (max-width:875px)': {
            display:"flex",
         },
        
    },
    
    hero:{
        width:"336px",
        aspectRatio:"1/1",
        '@media (max-width:940px)': {
            width:"300px",
            height:"300px",
        },
        '@media (max-width:800px)': {
            width:"250px",
            height:"250px",
        },
        '@media (max-width:600px)': {
            width:"190px",
            height:"190px",
        },
        display:'grid',
        gridTemplateColumns: "1fr",
        justifyContent:"center",
        alignItems:"center",
        placeItems: "center",
        backgroundColor:"transparent",
      
    },
    image:{
        backgroundColor:"transparent",
        gridArea:"1/1",
        maxWidth:187,

        '@media (max-width:800px)': {
            maxWidth:150,
        },
        '@media (max-width:600px)': {
            maxWidth:125,
        }
        
    }


})

export default function AboutMe(props) {

    const data = useStaticQuery(graphql`
    query MyQuery {
        contentfulGeneralInfos {
            infos {
              childMdx {
                body
              }
            }
        }
        
    }
    `)
    
    const infos = data.contentfulGeneralInfos.infos.childMdx.body;

    const classes = useStyles();

    return (
        <div>
            <section className={classes.about}>    
                <div className={classes.container}>
                    <div className={classes.infos}>
                        <div className={classes.hiThere}>
                            <h1>Hi there</h1>
                            <img className={classes.hello} src="/hello.png" alt="hello"/>
                        </div>
                        <div className={classes.nameAndSocials}>
                            <h1>I'm Momar Touré</h1>
                            <div className={classes.socials}>
                                <a href="https://www.linkedin.com/in/momar49" rel="noreferrer" target="_blank"><img src="/linkedin.svg" alt="linkedin"/></a> 
                                <a href="https://www.github.com/mftoure" rel="noreferrer" target="_blank"><img src="/github.svg" alt="github"/></a> 
                                <a href="" target="_blank"><img src="/twitter.svg" alt="twitter"/></a> 
                                
                            </div>
                        </div>
                        <MDXRenderer>{infos}</MDXRenderer>
            
                    </div>
                    <div className={classes.hero}>
                        <StaticImage placeholder="NONE" style={{width:"100%",height:"100%",gridArea:"1/1"}} src="./../../static/hero-background.png" alt="hero-background" layout="fullWidth" quality={100}/>
                        <StaticImage placeholder="NONE" className={classes.image} src="./../images/me.png" alt="hero"  quality={100} layout="constrained" placeholder="blurred"/>
                        
                    </div>
                </div>

            </section>
        </div>
    );
}


