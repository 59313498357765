import React from 'react';
import {makeStyles, Card, Button} from '@material-ui/core'
import {graphql, useStaticQuery, Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const useStyles = makeStyles({
    latestBlogs:{
        marginTop:120,
    },
    top:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:10,
        

        "& h4":{
            fontStyle: "normal",
            fontWeight: "normal",
            margin:0,
            fontSize: 20,
            lineHeight: "153%",
            color:"#FFFFFF"
        }

    },
    button:{
        background:"none",
        border:"none",
        boxShadow:"none",
        transition:"all .1s ease-in-out",
        
        "&:hover":{
            transform: "scale(1.02)",
            background: 'rgba(255, 255, 255, 0)',
            boxShadow:"none",
        },

        
    },
    cards:{
        display:"grid",
        gridAutoRows:150,
        gridTemplateColumns:"repeat(auto-fit,minmax(200px,1fr))",
        justifyContent:"center",
        gap:10,

        '@media (max-width:473px)': {
            gridTemplateColumns: "repeat(auto-fit,minmax(180px,1fr))",
        },

    },
    card:{
        background:"#032E3C",
        height:150,
        boxShadow: "rgba(2, 8, 20, 0.05) 0px 0.175em 0.5em",
        "&:hover" :{
            boxShadow: "rgba(2, 8, 20, 0.3) 0px 0.35em 1.175em",
            transform: "translateY(-0.2px) scale(1.01)",
         },

        "& h3":{
            fontWeight: "bold",
            fontSize: 17,
            lineHeight: "115%",
            width:"100%",
            color:"#68F4A9",
            margin:0,
            

            '@media (max-width:530px)': {
                fontSize:16,
                marginTop:10,
            },
        },
        "& span":{
            fontWeight: 500,
            fontSize: 12,
            color:"#68F4A9",
            marginBottom:8,
        },
        
    },
    /*cardPair:{
        background:"#68F4A9",
        "& h3":{
            color: "#01181F",
        },
        "& span":{
            color:"#01181F"
        }
    },*/
    image:{
        
        width:"max-content",
        borderRadius:50,
        marginTop:10,
    },
    cardContent:{
        margin: "0 auto",
        width:"90%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        gap:5,
    }
})

function LatestBlogs(props) {
    
    const classes = useStyles();
    
    const data = useStaticQuery(graphql`
    {
        allContentfulBlog(limit: 5, sort: {fields: updatedAt, order: DESC}) {
          nodes {
            id
            slug
            updatedAt(formatString: "MMM DD, YYYY")
            title
            image {
              gatsbyImageData(quality:100)
            }
          }
        }
      }
    `)
    
    const cards = data.allContentfulBlog.nodes    
    
    return (
        <div className={classes.latestBlogs}>
            <div className={classes.top}>
                <h4>Latest articles</h4>
                <Link to="/blog">
                    <Button
                            variant="contained"
                            className={classes.button}
                            endIcon={<img src="/right-arrow.svg" alt="right-arrow-icon"/>}
                        >VIEW ALL</Button> 
                </Link>
            </div>
            <div className={classes.cards}>
                {
                cards.map((card,i) => {
                    
                    return <Link to={"/blog/"+card.slug} key={card.id}>
                            <Card className={classes.card +" "+ (i%2===0 && classes.cardPair)} >
                            <div className={classes.cardContent}>
                                <GatsbyImage className={classes.image} image={card.image.gatsbyImageData} alt="image-blog"/>
                                <h3>{card.title}</h3>
                                <span>{card.updatedAt}</span>
                            </div>
                            </Card>
                        </Link>
                    
                })
                }
            </div>
            
        </div>
    );
}

export default LatestBlogs;