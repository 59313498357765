import React,{useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {Carousel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import listIcon from './../images/list-icon.svg';
import prevIcon from './../images/prev-icon.svg';
import nextIcon from './../images/next-icon.svg';
import line from './../images/line.svg';

const useStyles = makeStyles({

    work:{
        marginTop:0,
        paddingTop:50,
    },
    workContainer:{
        maxWidth:682,
        margin: "0 auto",
        display:"flex",
        flexDirection:"column",
        justifyContent: "flex-start",
        alignItems:"center",

        "& ul":{
            paddingLeft:0,
        },

        "& .carousel-indicators":{
            backgroundImage:`url(${line})`,
            backgroundRepeat: "round",
            backgroundSize:"cover",
            zIndex:'10',
            position:"absolute",
            display:"flex",
            top:41,
            width:"100%",
            justifyContent:"space-evenly",
            alignItems:"center",
            height:"max-content",
            margin:"0 auto",

            '@media (max-width:700px)': {
                width:"80%",
                top:43,
            },
            
            
            "& button":{
                opacity:1,
                width:12,
                height:12,
                backgroundColor:"#033746",
                borderRadius:"100%",

                '@media (max-width:700px)': {
                    width:9,
                    height:9,
                },
            },
            "& .active":{
                width:18,
                height:18,
                backgroundColor:"#68F4A9",

                '@media (max-width:700px)': {
                    width:15,
                    height:15,
                },
            },
        },
        "& .carousel-control-prev, .carousel-control-next":{
            position:"absolute",
            top:50,
            opacity:1,
            height:"max-content",
            margin:"0 -60px",
            '@media (max-width:700px)': {
                margin:0,
            },
            
            "& .carousel-control-prev-icon":{
                backgroundImage:`url(${prevIcon})`,
                width:22,
                height:22,
            },
            "& .carousel-control-next-icon":{
                width:22,
                height:22,
                backgroundImage:`url(${nextIcon})`
            }
        }
        
        
    },
    dots:{
        
        position:"relative",
        top:62,
        width:"80%",
    },
    top:{
        marginTop:100,
        marginRight:"auto",
        '@media (max-width:711px)': {
            marginRight:0,
        },
        "& h1":{
            fontWeight:500,
            fontSize:40,
            '@media (max-width:711px)': {
                textAlign:"center",
                fontSize:35,
            },
            "& span":{
                color:"#68F4A9",
            },
            
        },

    },
    date:{
        textAlign:"center",
        margin:"0 auto",
        marginTop:20,
        fontSize:17,
        fontWeight:500,
    },
    
    positionAndCompany:{
        marginTop:70,
        marginBottom:10,
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center",
        gap:6,
        '@media (max-width:500px)': {
            flexDirection:"column",
        },

        "& h4":{
            color:"#FFFFFF",
            fontSize:19,
            fontWeight:500,
            margin:0,

            '@media (max-width:600px)': {
                fontSize: 17,
            },
           
        },

    },
    company:{
        fontSize:19,
        fontWeight:500,
        color:"#68F4A9",

        "&:hover":{
            color:"#68F4A9",
        },

        '@media (max-width:600px)': {
            fontSize: 17,
        },
       
        
    },
    durationAndLocation:{
        marginBottom:40,
        display:"flex",
        gap:10,
        "& span":{
            fontSize:15,
            color:"#D0D0D0",

            '@media (max-width:600px)': {
                fontSize: 14,
            }
            
        }
    },
    description:{
        maxWidth:500,
        minHeight:300,

        "& ul":{
            listStyleImage:`url(${listIcon})`,
            
        },
        
        "& li":{
            color: "#EAEAEA",
            lineHeight:"140%",
            marginBottom:30,
            fontSize:17,
            paddingLeft:8,
            '@media (max-width:700px)': {
                fontSize: 15,
            }
        },
        "& li::maker":{
            marginRight:20,
        },
        "& strong":{
            color:"#68F4A9",
            fontWeight:400,
        }
    },
    
})
function Work(props) {


    const classes = useStyles();

    const data = useStaticQuery(graphql`
    {
        allContentfulWork(sort: {fields: date}) {
          nodes {
            company
            duration
            description {
              childMdx {
                body
              }
            }
            id
            link
            position
            location
            date(formatString: "MMM YYYY")
          }
        }
      }
    `);
      const jobs = data.allContentfulWork.nodes;
     
      const [index, setIndex] = useState(jobs.length - 1);

      const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
      };

    return (
        <div className={classes.work} id="work">
            <div className={classes.top}>
                <h1>Work</h1>
            </div>
            <div className={classes.workContainer}>
            <Carousel interval={null} slide={false} activeIndex={index} onSelect={handleSelect}>
            {
                jobs.map((job) => {
                    return (
                    <Carousel.Item key={job.id}>
                        <p className={classes.date}>{job.date}</p>
                        
                        <div className={classes.infos}>
                            <div className={classes.positionAndCompany}>
                                <h4>{job.position}</h4>
                                <a className={classes.company} href={job.link} target="_blank" rel="noreferrer">{"@ "+job.company}</a>
                            </div>
                            <div className = {classes.durationAndLocation}>
                                <span>{job.duration}</span>
                                <span>-</span>
                                <span>{job.location}</span>
                            </div>
                            <div className= {classes.description}>
                                <MDXRenderer>{job.description.childMdx.body}</MDXRenderer>
                            </div>
                        </div>
                    </Carousel.Item>
                    )
                })
            }
            </Carousel>
            </div>
            
        
        </div>
    );
}

export default Work;